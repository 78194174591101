<template>
  <BaseCard
    :resource="$data.$constants.RESOURCES.RESOURCE_NON_CONFORMITIES"
    :footer-visible="false"
    actions-visible
    :loading="loading"
  >
    <template #title>
      <div>
        <p class="mb-0"><small class="text-dark font-weight-bold">No conformidad Nº {{ nonConformity ? nonConformity.number : '' }}</small></p>
        <p v-if="nonConformity.created_at"><small class="text-transform-none">FECHA EN EL SGI: <span class="font-weight-bold font-weight-bold">{{ nonConformity ? nonConformity.created_at : '' }}</span></small></p>
        <div class="font-weight-bold capitalize-first-letter text-lowercase text-dark h3 mb-0">
          {{ nonConformityOriginName }}
          <StatusBadge
            class="font-small-3 ml-2"
            :status="nonConformityStatus"
          />
          <StatusBadge
            class="font-small-3 ml-2"
            :status="nonConformityRealOrPotential"
          />
        </div>
        <p><small class="text-transform-none">Fecha apertura: <span class="font-weight-bold">{{ nonConformityDate }}</span></small></p>
      </div>
    </template>
    <template #actions>
      <b-link
        class="d-inline-block px-50 text-indigo"
        @click="handleDownloadIconClick"
      >
        <feather-icon
          icon="DownloadIcon"
          size="18"
        />
      </b-link>
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_NON_CONFORMITIES,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
        class="d-inline-block px-50 text-indigo"
        @click="$router.push({ name: 'editNonConformity', params: { id: nonConformity ? nonConformity.id : null } })"
      >
        <feather-icon
          v-b-tooltip.hover 
          title="Editar"
          icon="Edit2Icon"
          size="18"
        />
      </b-link>
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_NON_CONFORMITIES,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
        class="d-inline-block px-50 text-danger"
        @click="handleDeleteIconClick"
      >
        <feather-icon
          v-b-tooltip.hover 
          title="Eliminar"
          icon="TrashIcon"
          size="18"
        />
      </b-link>
    </template>
    <ProfilePanel :profile="nonConformityProfile" />
    <b-row class="px-2 py-1">
      <b-col cols="12">
        <div class="font-weight-bold">
          Resumen:
        </div>
        <div class="text-light">
          {{ nonConformity ? nonConformity.resume : '' }}
        </div>
      </b-col>
      <b-col
        cols="12"
        class="py-1"
      >
        <div class="font-weight-bold">
          Descripción:
        </div>
        <div class="text-light">
          {{ nonConformity ? nonConformity.description : '' }}
        </div>
      </b-col>
      <b-col
        cols="12"
        class="py-1"
      >
        <div class="font-weight-bold">
          Análisis de causas:
        </div>
        <div class="text-light">
          {{ nonConformity ? nonConformity.cause_analysis : '' }}
        </div>
      </b-col>
      <b-col
        cols="12"
        class="py-1"
      >
        <div class="font-weight-bold">
          Acción inmediata:
        </div>
        <div class="text-light">
          {{ nonConformity ? nonConformity.inmediate_action : '' }}
        </div>
      </b-col>
      <b-col
        cols="12"
        class="py-1"
      >
        <div class="font-weight-bold">
          Evaluación de la eficacia:
        </div>
        <div class="text-light">
          {{ nonConformity ? nonConformity['evaluation_effectiveness_non-conformity'] : '' }}
        </div>
      </b-col>
    </b-row>
  </BaseCard>
</template>

<script>
import NonConformitiesApi from '@/api/non-conformities-api'
import { mapActions } from 'vuex'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'

export default {
  name: 'NonConformityProfileCard',
  components: { ProfilePanel, StatusBadge, BaseCard },
  props: {
    nonConformity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    nonConformityOriginName() {
      return (this.nonConformity?.origin?.name || 'Sin origen') + this.nonConformityOtherOrigin
    },
    nonConformityOtherOrigin() {
      return this.nonConformity?.other_origin ? ` (${this.nonConformity?.other_origin})` : ''
    },
    nonConformityStatus() {
      return this.nonConformity?.status?.name || ''
    },
    nonConformityDate() {
      return this.$options.filters.formatDate(this.nonConformity?.non_conformity_date)
    },
    nonConformityRealOrPotential() {
      return this.nonConformity?.real_or_potential || 'real'
    },
    nonConformityDateClose() {
      if (!this.nonConformity?.non_conformity_date_close) {
        return 'Sin cerrar'
      }

      return this.$options.filters.formatDate(this.nonConformity.non_conformity_date_close)
    },
    nonConformityProfile() {
      const profile = [
        { label: 'Nombre emisor', value: this.nonConformity?.issuer_name || 'Sin nombre' },
        { label: 'Teléfono emisor', value: this.nonConformity?.issuer_phone || 'Sin teléfono' },
        { label: 'Dirección emisor', value: this.nonConformity?.issuer_address || 'Sin dirección' },
        { label: 'Email emisor', value: this.nonConformity?.issuer_email || 'Sin email' },
        { label: 'Fecha de cierre', value: this.nonConformityDateClose },
        { label: 'Departamento afectado', value: this.nonConformity?.department?.name || 'Sin departamento afectado' },
      ]
      if (this.nonConformity?.provider) {
        profile.push({ label: 'Proveedor', value: this.nonConformity?.provider?.name || '' })
      }

      if (this.nonConformity?.job) {
        profile.push({ label: 'Proyecto', value: this.nonConformity?.job?.name || '' })
      }

      if (this.nonConformity?.issuer) {
        profile.push({ label: 'Reclamac./Sugerencia', value: this.nonConformity?.issuer?.name || '' })
      }

      if (this.nonConformity?.internal_or_external) {
        profile.push({ label: 'Tipo', value: this.nonConformity?.internal_or_external || '' })
      }

      return profile
    },
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async handleDeleteIconClick() {
      if (!this.nonConformity) {
        return
      }

      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro.',
      })

      if (!response.isConfirmed) {
        return
      }

      this.setLoading(true)
      try {
        await NonConformitiesApi.delete(this.nonConformity.id)
        await this.$router.replace({ name: 'nonConformities' })
      } finally {
        this.setLoading(false)
      }
    },
    async handleDownloadIconClick() {
      if (!this.nonConformity) {
        return
      }

      this.loading = true
      try {
        await NonConformitiesApi.printNonConformity(this.nonConformity.id)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
